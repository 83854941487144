import React from 'react'
import styled from '@emotion/styled'
import { darken, lighten } from 'polished'
import { ArrowDown } from 'react-feather'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { useCopyToClipboard } from 'react-use'

import { Theme, Mq } from '../styles/settings'

const BaseButton = styled.button`
  padding: 0 2rem;
  font-size: 1rem;
  letter-spacing: 2px;
  font-weight: ${(props) => props.theme.weight.semiBold};
  transition: ${(props) => props.theme.transition};
  cursor: pointer;
  text-transform: uppercase;

  &.base {
    background-color: ${(props) => props.theme.colors.brandPrimary};
    border: 3px solid;
    border-color: ${(props) => props.theme.colors.brandPrimary};
    color: ${(props) => props.theme.colors.black};
    height: 3rem;

    &:hover,
    &:focus,
    &:active {
      border: 3px solid ${(props) => darken(0.1, props.theme.colors.brandPrimary)};
      background-color: ${(props) => darken(0.1, props.theme.colors.brandPrimary)};
    }
  }

  &.disabled {
    border: 3px solid;
    background-color: ${(props) => props.theme.colors.grey};
    border-color: ${(props) => props.theme.colors.grey};
    color: ${(props) => props.theme.colors.black};
    height: 3rem;
    cursor: default;

    &:hover,
    &:focus,
    &:active {
      border: 3px solid ${(props) => props.theme.colors.grey};
      background-color: ${(props) => props.theme.colors.grey};
    }
  }

  ${Mq.md} {
    font-size: 1.2rem;
  }
`

const BaseAnchorButton = styled.button`
  padding: 0 2rem;
  font-size: 1rem;
  background-color: ${(props) => props.theme.colors.brandPrimary};
  color: ${(props) => props.theme.colors.black};
  letter-spacing: 2px;
  font-weight: ${(props) => props.theme.weight.semiBold};
  transition: ${(props) => props.theme.transition};
  cursor: pointer;
  text-transform: uppercase;
  border: 3px solid ${(props) => props.theme.colors.brandPrimary};
  height: 3rem;
  display: inline-flex;
  width: max-content;
  align-items: center;
  outline: none;

  &:hover,
  &:focus,
  &:active {
    border: 3px solid ${(props) => darken(0.1, props.theme.colors.brandPrimary)};
    background-color: ${(props) => darken(0.1, props.theme.colors.brandPrimary)};
    svg {
      transform: translate(0px, 4px);
    }
  }

  svg {
    margin-left: 2rem;
    transition: ${(props) => props.theme.transition};
  }

  ${Mq.md} {
    font-size: 1.2rem;
  }
`

const BaseSubmitButton = styled.button`
  padding: 0 2rem;
  font-size: 1.2rem;
  background-color: ${(props) => props.theme.colors.brandPrimary};
  color: ${(props) => props.theme.colors.black};
  letter-spacing: 2px;
  font-weight: ${(props) => props.theme.weight.semiBold};
  transition: ${(props) => props.theme.transition};
  cursor: pointer;
  text-transform: uppercase;
  border: 3px solid ${(props) => props.theme.colors.brandPrimary};
  height: 3rem;

  &:hover,
  &:focus,
  &:active {
    border: 3px solid ${(props) => darken(0.1, props.theme.colors.brandPrimary)};
    background-color: ${(props) => darken(0.1, props.theme.colors.brandPrimary)};
  }

  &.disabled {
    border: 3px solid ${(props) => props.theme.colors.lightgrey};
    background-color: ${(props) => props.theme.colors.lightgrey};
    color: ${(props) => props.theme.colors.grey};

    &:hover,
    &:focus,
    &:active {
      background-color: ${(props) => props.theme.colors.lightgrey};
      color: ${(props) => props.theme.colors.grey};
    }
  }
`

const BaseAccentButton = styled(BaseButton)`
  background-color: initial;
  color: ${(props) => props.theme.colors.black};
  cursor: pointer;
  border: 3px solid ${(props) => props.theme.colors.brandPrimary};

  &:hover,
  &:focus,
  &:active {
    color: ${(props) => darken(0.1, props.theme.colors.brandPrimary)};
    background-color: transparent;
    border-color: ${(props) => darken(0.1, props.theme.colors.brandPrimary)};
  }

  &.clipboard {
    font-size: 14px;
    padding: 4px 12px;
    color: ${(props) => props.theme.colors.white};
    outline: none;
    &:focus {
      border: 3px solid ${(props) => props.theme.colors.brandTertiary};
    }
  }

  &.clipped {
    font-size: 14px;
    padding: 4px 12px;
    color: ${(props) => props.theme.colors.white};
    outline: none;
    border: 3px solid ${(props) => lighten(0.2, props.theme.colors.brandPrimary)};
  }
`

export function Button(props) {
  return (
    <BaseButton
      className={props.disabled ? 'disabled' : 'base'}
      disabled={props.disabled}
      type={props.type}
      onClick={props.onClick}
    >
      {props.title}
    </BaseButton>
  )
}

export function SubmitButton(props) {
  return (
    <BaseSubmitButton
      className={props.className}
      onClick={props.onClick}
      type={props.type}
      disabled={props.disabled}
      {...props}
      ariaLabel="Submit"
    >
      {props.title}
    </BaseSubmitButton>
  )
}

export function AnchorButton(props) {
  return (
    <AnchorLink href={props.url} offset={props.offset}>
      <BaseAnchorButton
        disabled={props.disabled ? props.disbaled : null}
        type={props.type}
        className={props.className ? props.className + ' brand-button' : 'brand-button'}
      >
        {props.title} <ArrowDown size={22} color={Theme.colors.black} />
      </BaseAnchorButton>
    </AnchorLink>
  )
}

export function AccentButton(props) {
  return (
    <BaseAccentButton
      disabled={props.disabled ? props.disbaled : null}
      type={props.type}
      className="brand-button brand-button--accent"
      variant="secondary"
    >
      {props.title}
    </BaseAccentButton>
  )
}

export function ClipboardCopy(props) {
  const [state, copyToClipboard] = useCopyToClipboard()

  const toggleClass = () => {
    copyToClipboard(props.value)
  }
  return (
    <BaseAccentButton
      disabled={props.disabled ? props.disbaled : null}
      type={props.type}
      className={state.clipped ? 'clipped' : 'clipboard'}
      variant="secondary"
      onClick={toggleClass}
    >
      {state.value ? 'copied' : 'copy'} to clipboard
    </BaseAccentButton>
  )
}
