import React, { useState, useRef, useEffect } from 'react'
import styled from '@emotion/styled'
import { Flex, Box } from '@rebass/emotion'
import { Play } from 'react-feather'
import ReactModal from 'react-modal'
import { X } from 'react-feather'

import { Theme, Mq } from '../styles/settings'
import VideoBackground from '../assets/hero__video.jpg'
import Video from '../atoms/Video'
import Animation from '../atoms/Animation'
import { H5 } from '../atoms/Text'

const VideoSection = styled(Box)`
  background-size: cover;
  background-position: center;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  z-index: 2;
  cursor: pointer;
  margin-top: 0;
  margin-bottom: 4rem;

  ${Mq.md} {
    right: 0;
    top: 0;
    background-image: url('${VideoBackground}');
    position: absolute;
    margin-top: 0;
    margin-bottom: 0;
  }
`

const RadialShadow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  width: 100%;

  H5 {
    font-weight: ${(props) => props.theme.weight.bold};
    color: ${(props) => props.theme.colors.black};
    min-width: max-content;
    margin-right: 1rem;
    z-index: 2;
  }

  ${Mq.md} {
    flex-direction: column;

    H5 {
      margin-bottom: 1rem;
      color: ${(props) => props.theme.colors.white};
      min-width: auto;
      margin-right: 1rem;
    }
  }
`

const PlayButton = styled.div`
  width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ${(props) => props.theme.transitionSlothy};
  cursor: pointer;
  z-index: -1;
  background: ${(props) => props.theme.colors.brandPrimary};

  svg {
    margin-left: 2px;
    fill: ${(props) => props.theme.colors.white};
    stroke: ${(props) => props.theme.colors.white};

    ${Mq.md} {
      fill: ${(props) => props.theme.colors.black};
      stroke: ${(props) => props.theme.colors.black};
    }
  }
  ${Mq.md} {
    background: ${(props) => props.theme.colors.white};
    width: 60px;
    height: 60px;
    border-radius: 60px;
  }
`

const CloseModal = styled.div`
  position: absolute;
  top: -40px;
  right: -4px;

  svg {
  transition: 0.2s ease-in-out;
  cursor: pointer;
  :hover {
    stroke: ${(props) => props.theme.colors.brandPrimary};
  }
`

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: Theme.colors.black,
    border: '0px',
    padding: '0px',
    borderRadius: '0px',
    width: '90%',
    maxWidth: '970px',
    overflow: 'visible',
  },
  overlay: {
    backgroundColor: 'RGBA(54,59,61,0.8)',
    backdropFilter: 'blur(4px)',
    width: '100vw',
    zIndex: '9999',
  },
}

function useHover() {
  const [value, setValue] = useState(false)

  const ref = useRef(null)

  const handleMouseOver = () => setValue(true)
  const handleMouseOut = () => setValue(false)

  useEffect(
    () => {
      const node = ref.current
      if (node) {
        node.addEventListener('mouseover', handleMouseOver)
        node.addEventListener('mouseout', handleMouseOut)
        // node.addEventListener('mousedown', handleMouseDown)

        return () => {
          node.removeEventListener('mouseover', handleMouseOver)
          node.removeEventListener('mouseout', handleMouseOut)
        }
      }
    },
    [ref.current], // Recall only if ref changes
  )

  return [ref, value]
}

export function HeroVideo(props) {
  const [hoverRef, isHovered] = useHover()
  const [modal, showModal] = useState(false)

  const motionVariants = {
    hidden: {
      opacity: 0,
      y: '-20px',
    },
    visible: {
      opacity: 1,
      y: '0px',
    },
  }

  return (
    <>
      <VideoSection onClick={() => showModal(true)} ref={hoverRef}>
        <RadialShadow>
          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.8 }}
          >
            <Flex alignItems="center" flexDirection={['row', 'row', 'column']}>
              <H5>The Initiative</H5>
              <PlayButton
                style={
                  isHovered
                    ? {
                        boxShadow: '0px 0px 120px 300px rgba(0, 0, 0, 0.15)',
                        background: Theme.colors.brandPrimary,
                        zIndex: 0,
                      }
                    : null
                }
              >
                <Play />
              </PlayButton>
            </Flex>
          </Animation>
        </RadialShadow>
      </VideoSection>
      <ReactModal
        style={modalStyles}
        isOpen={modal}
        contentLabel="ScreeningKit"
        onRequestClose={() => showModal(false)}
      >
        <CloseModal onClick={() => showModal(false)}>
          <X color="white" />
        </CloseModal>
        <Video url="https://vimeo.com/460707571" type="vimeo" />
      </ReactModal>
    </>
  )
}
