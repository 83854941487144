import React, { useState, useRef, useEffect } from 'react'
import styled from '@emotion/styled'
import { Box } from '@rebass/emotion'
import ReactModal from 'react-modal'
import { X } from 'react-feather'

import { Theme, Mq } from '../styles/settings'
import Video from '../atoms/Video'
import { Paragraph, H6 } from '../atoms/Text'
import Animation from '../atoms/Animation'
import { Play } from 'react-feather'

const ImageBox = styled(Box)`
  background: ${(props) => props.theme.colors.darkGrey};
  transition: ${(props) => props.theme.transition};
  cursor: pointer;
`

const Image = styled.div`
  background: #ededed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  background: url('${(props) => props.background}');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 240px;
  box-shadow: none;
  transition: ${(props) => props.theme.transition};
`

const Description = styled(Box)`
  padding: 2rem;
  background: ${(props) => props.theme.colors.darkGrey};
  margin-bottom: 2rem;

  p {
    color: ${(props) => props.theme.colors.white};
    font-weight: ${(props) => props.theme.weight.regular};
  }

  H6 {
    font-weight: ${(props) => props.theme.weight.bold};
    color: ${(props) => props.theme.colors.brandPrimary};
    transition: ${(props) => props.theme.transition};
    text-align: center;
  }

  ${Mq.md} {
    margin-bottom: 0;
  }
`

const PlayButton = styled.div`
  background: ${(props) => props.theme.colors.white};
  width: 60px;
  height: 60px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ${(props) => props.theme.transition};
  cursor: pointer;
  z-index: 1;

  svg {
    margin-left: 2px;
    fill: ${(props) => props.theme.colors.black};
    stroke: ${(props) => props.theme.colors.black};
    transition: ${(props) => props.theme.transition};

    &.hovered {
      fill: ${(props) => props.theme.colors.brandPrimary};
      stroke: ${(props) => props.theme.colors.brandPrimary};
    }
  }
`

const CloseModal = styled.div`
  position: absolute;
  top: -40px;
  right: -4px;

  svg {
  transition: 0.2s ease-in-out;
  cursor: pointer;
  :hover {
    stroke: ${(props) => props.theme.colors.brandPrimary};
  }
`

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: Theme.colors.black,
    border: '0px',
    padding: '0px',
    borderRadius: '0px',
    width: '90%',
    maxWidth: '970px',
    overflow: 'visible',
  },
  overlay: {
    backgroundColor: 'RGBA(54,59,61,0.8)',
    backdropFilter: 'blur(4px)',
    width: '100vw',
    zIndex: '9999',
  },
}

function useHover() {
  const [value, setValue] = useState(false)

  const ref = useRef(null)

  const handleMouseOver = () => setValue(true)
  const handleMouseOut = () => setValue(false)

  useEffect(
    () => {
      const node = ref.current
      if (node) {
        node.addEventListener('mouseover', handleMouseOver)
        node.addEventListener('mouseout', handleMouseOut)
        // node.addEventListener('mousedown', handleMouseDown)

        return () => {
          node.removeEventListener('mouseover', handleMouseOver)
          node.removeEventListener('mouseout', handleMouseOut)
        }
      }
    },
    [ref.current], // Recall only if ref changes
  )

  return [ref, value]
}

export function ScreeningKitLink(props) {
  const [hoverRef, isHovered] = useHover()
  const [modal, showModal] = useState(false)

  const motionVariants = {
    hidden: {
      opacity: 0,
      x: '-20px',
    },
    hiddenUp: {
      opacity: 0,
      y: '-20px',
    },
    visible: {
      opacity: 1,
      x: 0,
      y: 0,
    },
  }
  return (
    <Box width={[1, 1, 1 / 3]} px={[1, 1, 3]}>
      <Animation
        initial="hidden"
        animate="visible"
        variants={motionVariants}
        transition={{ ease: 'easeInOut', duration: 0.6, delay: 0 }}
      >
        <ImageBox
          onClick={props.link ? () => window.open(props.link, '_blank') : () => showModal(true)}
          ref={hoverRef}
        >
          <Image
            background={props.background}
            style={
              isHovered
                ? {
                    boxShadow: 'inset 0px -200px 300px -200px rgba(107, 214, 175, 1)',
                  }
                : null
            }
          >
            {props.video && (
              <PlayButton>
                <Play className={isHovered && 'hovered'} />
              </PlayButton>
            )}
          </Image>

          <Description>
            <Animation
              initial="hidden"
              animate="visible"
              variants={motionVariants}
              transition={{
                ease: 'easeInOut',
                duration: 0.6,
                delay: 0,
              }}
            >
              <H6
                style={
                  isHovered
                    ? {
                        color: Theme.colors.brandPrimaryLighten,
                      }
                    : null
                }
              >
                {props.title}
              </H6>
            </Animation>
            <Animation
              initial="hidden"
              animate="visible"
              variants={motionVariants}
              transition={{
                ease: 'easeInOut',
                duration: 0.6,
                delay: 0.2,
              }}
            >
              <Paragraph>{props.description}</Paragraph>
            </Animation>
          </Description>
        </ImageBox>
      </Animation>

      <ReactModal
        style={modalStyles}
        isOpen={modal}
        contentLabel="ScreeningKit"
        onRequestClose={() => showModal(false)}
      >
        <CloseModal onClick={() => showModal(false)}>
          <X color="white" />
        </CloseModal>
        <Video url={props.url} type={props.type} />
      </ReactModal>
    </Box>
  )
}
