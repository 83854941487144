import React from 'react'
import styled from '@emotion/styled'
import { Flex, Box } from '@rebass/emotion'

import KTGWatchTrailer from '../assets/screening-kits/ktg_watch-trailer.jpg'
import KTGHostScreening from '../assets/screening-kits/ktg_host-screening.jpg'
import KTGFindPath from '../assets/screening-kits/ktg_find-path.jpg'

import FFWatchTrailer from '../assets/screening-kits/ff_watch-trailer.jpg'
import FFWatchFilm from '../assets/screening-kits/ff_watch-film.jpg'
import FFHostScreening from '../assets/screening-kits/ff_host-screening.jpg'

import GFWatchTrailer from '../assets/screening-kits/gather_trailer.jpg'
import GFBookScreening from '../assets/screening-kits/gather_book-screening.jpg'
import GFLearnMore from '../assets/screening-kits/gather_learn-more.jpg'

import { Mq } from '../styles/settings/theme'
import Animation from '../atoms/Animation'
import { H4, Paragraph } from '../atoms/Text'
import { ScreeningKitLink } from '../molecules/ScreeningKitLink'
import { WideContainer, Container } from '../atoms/Container'

const ScreeningKitWrapper = styled(Box)`
  padding-top: 4%;
  padding-bottom: 5%;
  background-color: ${(props) => props.theme.colors.white};

  H4 {
    margin-bottom: 1rem;
  }

  &.dark {
    background-color: ${(props) => props.theme.colors.lightGrey};
  }
`

const StyledParagraph = styled(Paragraph)`
  max-width: 740px;
  margin-bottom: 3rem;
`

const ImageSection = styled(Container)`
  max-width: 1600px;
  width: 100%;
  margin: auto;
  padding-left: 2rem;
  padding-right: 2rem;

  ${Mq.md} {
    padding-left: 4rem;
    padding-right: 4rem;
  }
`

const TextSection = styled(Box)`
  max-width: 1600px;
  width: 100%;
  margin: auto;
  z-index: 1;
  position: relative;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;

  ${Mq.md} {
    padding-top: 0;
    padding-left: 4rem;
    padding-right: 4rem;
  }
`

export function ScreeningKitsList(props) {
  const motionVariants = {
    hidden: {
      opacity: 0,
      y: '-20px',
    },
    visible: {
      opacity: 1,
      y: '0px',
    },
  }

  return (
    <WideContainer>
      <ScreeningKitWrapper>
        <TextSection className="tall">
          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.6, delay: 0 }}
          >
            <H4>Gather Film</H4>
          </Animation>
          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }}
          >
            <StyledParagraph>
              Gather is an intimate portrait of the growing movement amongst Native Americans to
              reclaim their spiritual, political and cultural identities through food sovereignty,
              while battling the trauma of centuries of genocide.
            </StyledParagraph>
          </Animation>
        </TextSection>

        <ImageSection>
          <Flex width="auto" mx={-3} flexDirection={['column', 'column', 'row']}>
            <ScreeningKitLink
              title="Watch Trailer"
              // description="Sed eget massa sem nulla ac est lorem. Leo sem sagittis pulvinar."
              url="https://www.youtube.com/watch?v=M5ZTFe4y3c4"
              background={GFWatchTrailer}
              type="youtube"
              video
            />
            <ScreeningKitLink
              title="Book Screening"
              // description="Sed eget massa sem nulla ac est lorem. Leo sem sagittis pulvinar."
              link="https://gather.film/screenings/"
              background={GFBookScreening}
            />
            <ScreeningKitLink
              title="Learn More"
              // description="Sed eget massa sem nulla ac est lorem. Leo sem sagittis pulvinar."
              link="https://gather.film/resources/"
              background={GFLearnMore}
            />
          </Flex>
        </ImageSection>
      </ScreeningKitWrapper>

      <ScreeningKitWrapper className="dark">
        <TextSection className="tall">
          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.6, delay: 0 }}
          >
            <H4>Kiss The Ground Movie</H4>
          </Animation>
          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }}
          >
            <StyledParagraph>
              By regenerating the world’s soils, we can completely and rapidly stabilize Earth’s
              climate, restore lost ecosystems and create abundant food supplies.
            </StyledParagraph>
          </Animation>
        </TextSection>

        <ImageSection>
          <Flex width="auto" mx={-3} flexDirection={['column', 'column', 'row']}>
            <ScreeningKitLink
              title="Watch Trailer"
              // description="Sed eget massa sem nulla ac est lorem. Leo sem sagittis pulvinar."
              url="https://youtu.be/K3-V1j-zMZw"
              type="youtube"
              background={KTGWatchTrailer}
              video
            />
            <ScreeningKitLink
              title="Host A Screening"
              // description="Sed eget massa sem nulla ac est lorem. Leo sem sagittis pulvinar."
              link="https://kissthegroundmovie.com/host-a-screening/"
              background={KTGHostScreening}
            />
            <ScreeningKitLink
              title="Find Your Path"
              // description="Sed eget massa sem nulla ac est lorem. Leo sem sagittis pulvinar."
              link="https://kisstheground.com/find-your-path/"
              background={KTGFindPath}
            />
          </Flex>
        </ImageSection>
      </ScreeningKitWrapper>

      <ScreeningKitWrapper flexDirection={['column', 'column', 'row']}>
        <TextSection className="tall">
          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.6, delay: 0 }}
          >
            <H4>Farmer's Footprint - Short Film</H4>
          </Animation>
          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }}
          >
            <StyledParagraph>
              The trials, learnings and victories of the four generation Breitkreutz family from
              Stoney Creek Farm transitioning from conventional farming to regenerative agriculture
              in Redwood Falls, Minnesota.
            </StyledParagraph>
          </Animation>
        </TextSection>

        <ImageSection>
          <Flex width="auto" mx={-3} flexDirection={['column', 'column', 'row']}>
            <ScreeningKitLink
              title="Watch Trailer"
              // description="Sed eget massa sem nulla ac est lorem. Leo sem sagittis pulvinar."
              url="https://vimeo.com/311936042"
              background={FFWatchTrailer}
              type="vimeo"
              video
            />
            <ScreeningKitLink
              title="Watch Film for Free"
              // description="Sed eget massa sem nulla ac est lorem. Leo sem sagittis pulvinar."
              background={FFWatchFilm}
              link="https://farmersfootprint.us/watch/"
            />
            <ScreeningKitLink
              title="Host A Screening"
              // description="Sed eget massa sem nulla ac est lorem. Leo sem sagittis pulvinar."
              link="https://farmersfootprint.us/screening-toolkit/"
              background={FFHostScreening}
            />
          </Flex>
        </ImageSection>
      </ScreeningKitWrapper>

      <section id="take-action"></section>
    </WideContainer>
  )
}
