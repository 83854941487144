import styled from '@emotion/styled'
import { Box, Flex } from '@rebass/emotion'
import React from 'react'
import { CheckCircle } from 'react-feather'

import { Theme, Mq } from '../styles/settings/theme'
import Animation from '../atoms/Animation'
import { Container } from '../atoms/Container'
import { H4, Paragraph } from '../atoms/Text'
import { LeadCapture } from '../molecules/LeadCapture'

const SignUpWrapper = styled.div`
  position: relative;
  width: 100%;
`

const SignUpContainer = styled.div`
  background: ${(props) => props.theme.colors.darkGrey};
  padding: 4rem 2rem;
  position: relative;

  p {
    max-width: 550px;
    padding-top: 1.5rem;
  }

  ${Mq.md} {
    padding: 4% 0 5%;
  }
`

const Diamond = styled.div`
  display: none;

  ${Mq.md} {
    background: ${(props) => props.theme.colors.darkGrey};
    width: 3.5rem;
    height: 3.5rem;
    transform: rotate(45deg);
    position: absolute;
    top: -1.75rem;
    left: 11rem;

    @media screen and (min-width: 1600px) {
      display: none;
    }
  }
`

const Alert = styled(Box)`
  ${Mq.md} {
    text-align: left;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: -6rem;

    p {
      padding-top: 2px;
      max-width: 700px;
    }

    svg {
      margin-right: 1rem;
    }
  }

  @media screen and (max-width: 1240px) {
    display: none;
  }

  @media screen and (max-width: 1330px) {
    p {
      max-width: 500px;
    }
  }
`

export function Signup(props) {
  const motionVariants = {
    hiddenLeft: {
      opacity: 0,
      x: '-2rem',
    },
    hiddenRight: {
      opacity: 0,
      x: '2rem',
    },
    invisible: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      x: 0,
    },
  }
  return (
    <SignUpWrapper>
      <Animation
        initial="invisible"
        animate="visible"
        variants={motionVariants}
        transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.6 }}
      >
        <Diamond />
      </Animation>
      <SignUpContainer>
        <Container>
          {props.takeActionPage ? null : (
            <Flex alignItems="center" position="relative" justifyContent="flex-end">
              <Animation
                initial="hiddenRight"
                animate="visible"
                variants={motionVariants}
                transition={{ ease: 'easeInOut', duration: 0.6, delay: 2 }}
              >
                <Alert>
                  <CheckCircle size={22} color={Theme.colors.brandPrimary} />
                  <Paragraph whiteText>
                    Email only used for future soil policy actions and never shared
                  </Paragraph>
                </Alert>
              </Animation>
            </Flex>
          )}
          <Flex alignItems="center" position="relative">
            <Animation
              initial="hiddenLeft"
              animate="visible"
              variants={motionVariants}
              transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.6 }}
            >
              <H4 whiteText>
                {props.takeActionPage
                  ? 'You need to add your location to take new action to support healthy soil policies. Enter your information to get started:'
                  : 'Take new action to support healthy soil policies'}
              </H4>
              <Paragraph whiteText>
                Communicating with your policy makers builds political will, and where there's a
                will there's a way.
              </Paragraph>
            </Animation>
          </Flex>

          <Animation
            initial="invisible"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 1.2, delay: 0.2 }}
          >
            <LeadCapture takeActionPage={props.takeActionPage} />
          </Animation>
        </Container>
      </SignUpContainer>
    </SignUpWrapper>
  )
}
