import React from 'react'
import styled from '@emotion/styled'
import { Flex, Box } from '@rebass/emotion'
import { Hidden } from '@material-ui/core'

import { Mq } from '../styles/settings'
import HeroImage from '../assets/hero_clovers.jpg'
import Animation from '../atoms/Animation'
import { Button, AnchorButton } from '../atoms/Button'
import { H1, H2, Paragraph } from '../atoms/Text'
import { WideContainer } from '../atoms/Container'
import { HeroVideo } from '../molecules/HeroVideo'
import { Message } from '../molecules/Message'

const SmallWrapper = styled(Box)`
  background-color: white;
  background-size: cover;
  background-position: center;
  background-image: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0.875) 50%),
    url('${(props) => props.backgroundImage}');

  H2 {
    margin-bottom: 1rem;
  }
`

const LargeWrapper = styled(Flex)`
  padding-top: 14%;
  padding-bottom: 14%;
  position: relative;
  background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0.83) 100%),
    url('${HeroImage}');
  background-size: cover;
  background-repeat: no-repeat;
  .button {
    margin-right: 1rem;
  }

  H1 {
    max-width: 740px;
    margin-bottom: 1rem;
  }

  ${Mq.md} {
    padding-top: 10%;
    padding-bottom: 10%;
  }

  @media screen and (max-width: 1240px) and (min-width: 1001px) {
    H1,
    p {
      max-width: 50%;
    }
  }
`

const HomepageParagraph = styled(Paragraph)`
  max-width: 640px;
  margin-bottom: 3rem;
`

const LargeHeroText = styled(Box)`
  max-width: 1600px;
  width: 100%;
  margin: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  z-index: 1;
  position: relative;
  align-items: center;

  ${Mq.md} {
    padding-left: 4rem;
    padding-right: 4rem;
  }
`

const SmallHeroText = styled(Flex)`
  width: 100%;
  margin: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  z-index: 1;
  position: relative;
  align-items: center;
  flex-direction: column;
  margin-bottom: ${(props) => props.marginBottom};
  padding-bottom: 4rem;
  padding-top: 4rem;

  button {
    width: 100%;
  }
  H2 {
    margin-top 0;
  }
  ${Mq.md} {
    padding-top: ${(props) => props.paddingTop || '0'};
    flex-direction: row;
    max-width: 1600px;
    padding-left: 4rem;
    padding-right: 4rem;

    H2 {
      margin-top: ${(props) => (props.marginTop ? props.marginTop : '-2rem')};
    }
    button {
      width: auto;
    }
  }
`

const motionVariants = {
  hidden: {
    opacity: 0,
    y: '-20px',
  },
  visible: {
    opacity: 1,
    y: '0px',
  },
}

export function LargeHero(props) {
  return (
    <WideContainer>
      <LargeWrapper>
        <LargeHeroText>
          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.6, delay: 0 }}
          >
            <H1>A nation that builds its soil builds its future.</H1>
          </Animation>
          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }}
          >
            <HomepageParagraph>
              Contact your government representative and encourage them to learn about the
              importance of improving soil health through regenerative agriculture!
            </HomepageParagraph>
          </Animation>
          <Hidden mdUp>
            <HeroVideo />
          </Hidden>
          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.4 }}
          >
            <AnchorButton title="Take Action" className="button" url="#take-action" />
          </Animation>
        </LargeHeroText>
        <Hidden smDown>
          <HeroVideo />
        </Hidden>
      </LargeWrapper>

      <section id="take-action"></section>
    </WideContainer>
  )
}

export function SmallHero(props) {
  return (
    <WideContainer>
      <SmallWrapper backgroundImage={props.heroImage}>
        <SmallHeroText
          marginBottom={props.marginBottom}
          marginTop={props.marginTop}
          paddingTop={props.paddingTop}
          paddingBottom={props.paddingBottom}
        >
          <Box width={[1, 1, 'auto']} mt={[0, 0, props.margin || -5]}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={motionVariants}
              transition={{ ease: 'easeInOut', duration: 0.6, delay: 0 }}
            >
              <H2>{props.title}</H2>
            </Animation>
            <Animation
              initial="hidden"
              animate="visible"
              variants={motionVariants}
              transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }}
            >
              <HomepageParagraph>{props.subTitle}</HomepageParagraph>
            </Animation>
            {props.homeButton ? (
              <Animation
                initial="hidden"
                animate="visible"
                variants={motionVariants}
                transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.4 }}
              >
                <Button title={props.button} className="button" url="/" />
              </Animation>
            ) : (
              <Hidden smDown>
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={motionVariants}
                  transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.4 }}
                >
                  <AnchorButton title={props.button} className="button" url="#continue" />
                </Animation>
              </Hidden>
            )}
          </Box>
          {props.noSubContent ? null : <Message />}
        </SmallHeroText>
      </SmallWrapper>

      <section id="continue"></section>
    </WideContainer>
  )
}
