import React from 'react'
import styled from '@emotion/styled'
import { Box } from '@rebass/emotion'

import { Mq } from '../styles/settings'
import Animation from '../atoms/Animation'
import { SocialShares } from '../molecules/SocialShares'
import { H6 } from '../atoms/Text'

const SocialShare = styled(Box)`
  padding: 2.25rem 4rem 4rem;
  border: 2px solid ${(props) => props.theme.colors.brandPrimary};
  text-align: center;
  width: 120vw;
  margin-top: 4rem;

  H6.center {
    margin: 1rem 0 4rem;
    text-align: center;
  }

  img {
    padding-bottom: 2rem;
  }

  ${Mq.md} {
    width: 100%;
    margin-top: 0;
  }
`

export function ShareBox() {
  const motionVariants = {
    hiddenLeft: {
      opacity: 0,
      x: '-20px',
    },
    hiddenRight: {
      opacity: 0,
      x: '20px',
    },
    invisible: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      x: 0,
      y: 0,
    },
  }

  return (
    <SocialShare>
      <Animation
        initial="hiddenRight"
        animate="visible"
        variants={motionVariants}
        transition={{ ease: 'easeInOut', duration: 0.6, delay: 1 }}
      >
        <H6 className="center">Share the initiative</H6>
      </Animation>
      <Animation
        initial="hiddenRight"
        animate="visible"
        variants={motionVariants}
        transition={{ ease: 'easeInOut', duration: 0.6, delay: 1.2 }}
      >
        <SocialShares delay={1.2} />
      </Animation>
    </SocialShare>
  )
}

export default ShareBox
