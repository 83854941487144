import React from 'react'
import styled from '@emotion/styled'
import Background from '../assets/climate-change.jpg'

import { Mq } from '../styles/settings'
import { Paragraph, H2 } from '../atoms/Text'
import Animation from '../atoms/Animation'
import { WideContainer, Container } from '../atoms/Container'
import { LinkedImages } from '../molecules/LinkedImages'
import { AdditionalResources } from '../organisms/AdditionalResources'

const StyledSection = styled.main`
  padding-top: 8rem;
  padding-bottom: 4rem;
  text-align: center;
  background-image: linear-gradient(
      360deg,
      #ffffff 40%,
      rgba(255, 255, 255, 0.5) 90%,
      rgba(255, 255, 255, 0.8) 100%
    ),
    url('${Background}');
  background-size: 160%;
  background-position-y: 0%;
  background-repeat: no-repeat;
  margin-bottom: 260px;
  ${Mq.md} {
    background-size: cover;
    background-position-y: 100%;
  }

  H2 {
    padding: 0 1rem 6rem;
    max-width: 500px;
    margin: auto;
    4 ${Mq.md} {
      padding: 0 0 6rem 0;
    }
  }

  p {
    max-width: 760px;
    margin: auto;
    padding: 0 2rem 1rem;

    ${Mq.md} {
      padding: 0 0 1rem 0;
    }
  }
`

export function EducationSection(props) {
  const motionVariants = {
    hidden: {
      opacity: 0,
      y: '-20px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }
  return (
    <WideContainer>
      <StyledSection>
        <Container>
          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.6, delay: 0 }}
          >
            <H2>A powerful solution is under your feet.</H2>
          </Animation>
          <Animation
            initial="hidden"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }}
          >
            <Paragraph>
              Scientists agree that agriculture has a big role to play in both reducing emissions
              and drawing down and storing carbon in the soil. Elected officials from both sides of
              the aisle support soil health and farmer prosperity.
            </Paragraph>
            <Paragraph>
              It will take a national effort and we know it’s not going to happen overnight. But, it
              can happen.
            </Paragraph>
            <Paragraph>
              Letting policy makers know you care about this solution creates political will. And
              where there’s will, there’s a way!
            </Paragraph>
          </Animation>
        </Container>
      </StyledSection>
      <LinkedImages />

      <AdditionalResources />
    </WideContainer>
  )
}
