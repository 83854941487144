import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Homepage from './pages/Homepage'
import ScrollTop from './atoms/ScrollTop'
import TakeAction from './pages/TakeAction'
import NoMatch from './pages/NoMatch'
import ScreeningKits from './pages/ScreeningKits'

import { Navigation } from './organisms/Navigation'
import { Footer } from './organisms/Footer'
import { CivicsContextProvider } from './contexts/GoogleCivicsContext'

const Routes = () => {
  return (
    <Router>
      <CivicsContextProvider>
        <ScrollTop />
        <Switch>
          <Route exact path="/">
            <Navigation home />
            <Homepage />
            <Footer home />
          </Route>
          <Route exact path="/screening-kits">
            <Navigation screeningKits />
            <ScreeningKits />
            <Footer screeningKits />
          </Route>
          <Route exact path="/take-action">
            <Navigation takeAction />
            <TakeAction />
            <Footer takeAction />
          </Route>
          <Route>
            <Navigation screeningKits />
            <NoMatch />
            <Footer screeningKits />
          </Route>
        </Switch>
      </CivicsContextProvider>
    </Router>
  )
}

export default Routes
