import React from 'react'
import MetaTags from 'react-meta-tags'
import metaImage from '../assets/meta-image.jpg'

import ScreeningKitHero from '../assets/hero_screening-kit.jpg'
import { WideContainer } from '../atoms/Container'
import { SmallHero } from '../organisms/Hero'
import { ScreeningKitsList } from '../organisms/ScreeningKitsList'

export function ScreeningKits() {
  return (
    <main className="atomic-page">
      <MetaTags>
        <title>US Soil Policy Action – Growing Awareness for Regenerative Agriculture</title>
        <meta
          property="og:title"
          content="US Soil Policy Action – Watch and Share Stories of Regeneration "
        />
        <meta
          name="description"
          content="See documentaries that explore the restorative potential of our food system when we focus on soil and community health."
        />
        <meta property="og:image" content={metaImage} />
      </MetaTags>
      <WideContainer>
        <SmallHero
          heroImage={ScreeningKitHero}
          title="Film Screening Kits"
          subTitle="Learn about regeneration and share stories of regeneration from across the country. Each documentary explores the restorative potential of our food system when we focus on soil and community health."
          button="View the screening kits"
          marginBottom="0px"
          paddingTop="6.75rem"
          marginTop="0px"
          margin="0"
          noSubContent
        />
        <ScreeningKitsList />
      </WideContainer>
    </main>
  )
}

export default ScreeningKits
