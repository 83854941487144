import React from 'react'
import styled from '@emotion/styled'
import { Flex, Box } from '@rebass/emotion'

import { Mq } from '../styles/settings'
import { Paragraph, H3, H6 } from '../atoms/Text'
import Animation from '../atoms/Animation'
import { Resource } from '../molecules/Resource'
import { WideContainer, Container } from '../atoms/Container'

const StyledBox = styled(Box)`
  display: none;

    ${Mq.md} {
      width: 100%;
      justify-content: flex-end;
      text-align: right;

      H6 {
        margin-top: 1.25rem;
        color: ${(props) => props.theme.colors.brandPrimary};
        font-weight: ${(props) => props.theme.weight.bold};
        font-family: ${(props) => props.theme.fonts.header};
        text-align: left;
        width: 100%;
      }
    }

  }
`

export function AdditionalResources(props) {
  const isMobile = window.innerWidth < 1000
  const motionVariants = {
    hidden: {
      opacity: 0,
      y: '-20px',
    },
    invisible: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <WideContainer>
      <Container>
        <Flex width={1} flexWrap="wrap" py={4} mt={[0, 0, 4]} mb={[4, 4, 0]}>
          <Box width={1} mb={4}>
            <Flex
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              flexDirection={['column', 'column', 'row']}
              px={['1.5rem', '1.5rem', 0]}
            >
              <Box width={1}>
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={motionVariants}
                  transition={{ ease: 'easeInOut', duration: 0.6, delay: 0 }}
                >
                  <H3>Additional Resources</H3>
                </Animation>
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={motionVariants}
                  transition={{ ease: 'easeInOut', duration: 0.6, delay: 0 }}
                >
                  <Paragraph maxWidth="640px">
                    If you have something to add to the map or a resource to offer please email us
                    at: <a href="mailto:info@healthysoilpolicy.org">info@healthysoilpolicy.org</a>
                  </Paragraph>
                </Animation>
              </Box>

              <StyledBox>
                <Animation
                  initial="invisible"
                  animate="visible"
                  variants={motionVariants}
                  transition={{ ease: 'easeInOut', duration: 1, delay: 1 }}
                >
                  <H6>HEALTHY SOIL = HEALTHY PLANET = HEALTHY PEOPLE</H6>
                </Animation>
              </StyledBox>
            </Flex>
          </Box>

          <Resource
            title="What is regenerative agriculture?"
            link={isMobile ? 'Learn More' : 'RegenerationInternational.com'}
            url="https://regenerationinternational.org/"
          />
          <Resource
            title="AgriBioSystems, Business Solutions"
            type="video"
            link="11 minute video"
            url="https://agribiosystems.com/"
          />
          <Resource
            title="The Three Sisters, Indigenous Farming"
            type="video"
            link="5 minute video"
            url="https://www.youtube.com/watch?v=hWURhP9sZpw#action=share"
          />
          <Resource
            title="Regreening the Desert"
            type="video"
            link="47 minute video"
            url="https://www.youtube.com/watch?v=IDgDWbQtlKI"
          />
          <Resource
            title="Hawaiian Indigenous Agriculture"
            type="video"
            link="56 minute video"
            url="https://www.youtube.com/watch?v=VXp1s-PYQcY"
          />
          <Resource
            title="Black Farmers in America"
            type="article"
            link="Center for American Progress"
            url="https://www.americanprogress.org/issues/economy/reports/2019/04/03/467892/progressive-governance-can-turn-tide-black-farmers/"
          />
          <Resource
            title="Good soil is the key to good agriculture"
            type="article"
            link="Wall Street Journal"
            url="https://www.wsj.com/articles/good-soil-is-the-key-to-good-agriculture-1439926406"
          />
          <Resource
            title="Can dirt save the earth?"
            type="article"
            link="New York Times"
            url="https://www.nytimes.com/2018/04/18/magazine/dirt-save-earth-carbon-farming-climate-change.html"
          />
          <Resource
            title="Earth’s rapidly degrading soil is bad news for human health"
            type="article"
            link="Forbes"
            url="https://www.forbes.com/sites/daphneewingchow/2020/06/24/earths-rapidly-degrading-soil-is-bad-news-for-human-health/"
          />
        </Flex>
      </Container>
    </WideContainer>
  )
}
