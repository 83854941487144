import React, { useContext, useState } from 'react'
import styled from '@emotion/styled'
import MetaTags from 'react-meta-tags'
import metaImage from '../assets/meta-image.jpg'
import ReactModal from 'react-modal'
import { X } from 'react-feather'

import { Theme } from '../styles/settings'
import TakeActionHero from '../assets/hero_take-action.jpg'
import { WideContainer } from '../atoms/Container'
import { SmallHero } from '../organisms/Hero'
import { Signup } from '../organisms/Signup'
import { Representatives } from '../organisms/Representatives'
import { EducationSection } from '../organisms/EducationSection'
import { CivicsContext } from '../contexts/GoogleCivicsContext'

const CloseModal = styled.div`
  position: absolute;
  top: -40px;
  right: -4px;

  svg {
  transition: 0.2s ease-in-out;
  cursor: pointer;
  :hover {
    stroke: ${(props) => props.theme.colors.brandPrimary};
  }
`

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: Theme.colors.black,
    border: '0px',
    padding: '0px',
    borderRadius: '0px',
    width: '90%',
    maxWidth: '1100px',
    overflow: 'visible',
  },
  overlay: {
    backgroundColor: 'RGBA(54,59,61,0.8)',
    backdropFilter: 'blur(4px)',
    width: '100vw',
    zIndex: '999',
  },
}

export function TakeAction(props) {
  const { address } = useContext(CivicsContext)

  const [modal, showModal] = useState(false)
  if (!address && !modal) showModal(true)
  return (
    <main className="home-page">
      <MetaTags>
        <title>US Soil Policy Action – Watch and Share Stories of Regeneration</title>
        <meta
          property="og:title"
          content="US Soil Policy Action – Watch and Share Stories of Regeneration"
        />
        <meta
          name="description"
          content="See documentaries that explore the restorative potential of our food system when we focus on soil and community health."
        />
        <meta property="og:image" content={metaImage} />
      </MetaTags>
      <WideContainer>
        <SmallHero
          heroImage={TakeActionHero}
          title="Contact your elected officials"
          subTitle="To contact officials copy the message to the right and click on their links below. You will be redirected to the selected platform where you can then share this message."
          button="Get The Message Out"
          marginBottom="-12rem"
        />

        <Representatives />
        <EducationSection />
      </WideContainer>

      <ReactModal
        style={modalStyles}
        isOpen={modal}
        contentLabel="ScreeningKit"
        onRequestClose={() => showModal(false)}
        shouldCloseOnOverlayClick={true}
      >
        <CloseModal onClick={() => showModal(false)}>
          <X color="white" />
        </CloseModal>
        <Signup takeActionPage />
      </ReactModal>
    </main>
  )
}

export default TakeAction
