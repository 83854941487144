import React from 'react'
import { Flex, Box } from '@rebass/emotion'
import styled from '@emotion/styled'
import { Globe, Phone } from 'react-feather'

import { Theme, Mq } from '../styles/settings/theme'
import Animation from '../atoms/Animation'

import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share'

export function SocialShares(props) {
  const motionVariants = {
    hiddenLeft: {
      opacity: 0,
      x: '-20px',
    },
    hiddenRight: {
      opacity: 0,
      x: '20px',
    },
    invisible: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      x: 0,
      y: 0,
    },
  }

  const StyledFlex = styled(Flex)`
    max-width: ${props.representative ? '100%' : '240px'};
    margin: 4rem auto 0;
    button.react-share__ShareButton {
      margin-left: 0;
      outline: none;
      ${Mq.md} {
        margin-left: ${props.margin};
      }
    }
    button.react-share__ShareButton svg circle {
      transition: ${(props) => props.theme.transition};
    }
    button.react-share__ShareButton:hover svg circle {
      fill: ${(props) => props.theme.colors.brandPrimary} !important;
    }

    ${Mq.md} {
      margin: auto;
      margin-left: ${props.margin};
    }
  `

  const IconButton = styled(Box)`
    background: ${(props) => props.theme.colors.brandPrimary};
    min-width: ${props.altSize || '40px'};
    height: ${props.altSize || '40px'};
    border-radius: ${props.altSize || '40px'};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
  `

  const DefaultMessage = `Join me in spreading awareness about regenerative agriculture as a solution to personal & planetary health! Visit Soilpolicyaction.us to easily contact all your Government Reps about the power and potential of healthy soil. Visit https://www.soilpolicyaction.us and share our message with your elected officials. #HealthySoilNow #FarmersFootprint #KissTheGround`

  return (
    <Animation
      initial="hiddenRight"
      animate="visible"
      variants={motionVariants}
      transition={{ ease: 'easeInOut', duration: 0.6, delay: props.delay }}
    >
      <StyledFlex justifyContent={props.representative ? 'flex-end' : 'space-between'} mx="auto">
        <FacebookShareButton
          url="https://soilpolicyaction.us"
          quote="Join me in spreading awareness about regenerative agriculture as a solution to personal & planetary health! Visit Soilpolicyaction.us to easily contact all your government reps about the power and potential of healthy soil. #HealthySoilNow #FarmersFootprint #KissTheGround"
        >
          <FacebookIcon
            size={props.size || 40}
            round={true}
            bgStyle={
              props.color ? { fill: Theme.colors.brandPrimary } : { fill: Theme.colors.white }
            }
            iconFillColor={props.color ? Theme.colors.black : Theme.colors.black}
          />
        </FacebookShareButton>
        <TwitterShareButton
          title="I encourage you to learn more about soil health & how we can achieve personal & planetary health by implementing regenerative agriculture. Visit soilpolicyaction.us to learn more & see which states are taking action #HealthySoilNow"
          url="https://soilpolicyaction.us"
        >
          <TwitterIcon
            size={props.size || 40}
            round={true}
            bgStyle={
              props.color ? { fill: Theme.colors.brandPrimary } : { fill: Theme.colors.white }
            }
            iconFillColor={props.color ? Theme.colors.black : Theme.colors.black}
          />
        </TwitterShareButton>
        <EmailShareButton subject="Why Soil Health Matters." separator body={DefaultMessage}>
          <EmailIcon
            size={props.size || 40}
            round={true}
            bgStyle={
              props.color ? { fill: Theme.colors.brandPrimary } : { fill: Theme.colors.white }
            }
            iconFillColor={props.color ? Theme.colors.black : Theme.colors.black}
          />
        </EmailShareButton>

        {props.representative && (
          <>
            <IconButton minWidth={props.altSize} height={props.size}>
              <Phone size={props.iconSize || 24} />
            </IconButton>
            <IconButton minWidth={props.altSize} height={props.size}>
              <Globe size={props.iconSize || 24} />
            </IconButton>
          </>
        )}
      </StyledFlex>
    </Animation>
  )
}
