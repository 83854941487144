import React from 'react'
import styled from '@emotion/styled'
import { Flex, Box } from '@rebass/emotion'
import { Hidden } from '@material-ui/core'

import { Mq } from '../styles/settings/theme'
import Animation from '../atoms/Animation'
import { H3, H4, H6 } from '../atoms/Text'
import { Representative } from '../atoms/Representative'

const PrepresentativeListContainer = styled(Box)`
  position: relative;
  padding: 2rem 1rem 3rem;
  background: ${(props) => props.theme.colors.white};
`

const RepresentativesGroup = styled.div`
  padding-top: 2rem;
  p {
    max-width: 600px;
    padding-top: 1.5rem;
  }

  H3 {
    padding: 1rem 2rem 1rem;

    ${Mq.md} {
      padding: 4rem 0 1rem;
    }
  }
`

const Header = styled(Flex)`
  display: none;

  ${Mq.md} {
    display: flex;
    background: ${(props) => props.theme.colors.black};
    padding: 1rem 3rem;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    H4 {
      color: ${(props) => props.theme.colors.white};
      &.bold {
        text-align: right;
        color: ${(props) => props.theme.colors.brandPrimary};
        font-weight: ${(props) => props.theme.weight.bold};
      }
    }
  }
`
const ZeroState = styled(Box)`
  background: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 2rem;
  text-align: center;
  ${Mq.md} {
    text-align: center;
  }
`

export function RepresentativeList(props) {
  const motionVariants = {
    hidden: {
      opacity: 0,
      y: '2rem',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }
  const { representatives } = props
  return (
    <RepresentativesGroup>
      <Flex alignItems="center" position="relative" pt={[props.paddingTop, props.paddingTop, 0]}>
        <Animation
          initial="hidden"
          animate="visible"
          variants={motionVariants}
          transition={{ ease: 'easeInOut', duration: 0.6 }}
        >
          <H3 whiteText>{props.segment} Representatives</H3>
        </Animation>
      </Flex>
      <Animation
        initial="hidden"
        animate="visible"
        variants={motionVariants}
        transition={{ ease: 'easeInOut', duration: 0.6, delay: 0 }}
      >
        <Header>
          <Box width={1 / 3}>
            <H4>Representative</H4>
          </Box>
          <Hidden smDown>
            <Box width={1 / 3}>
              <H4>Title</H4>
            </Box>
          </Hidden>
          <Box width={1 / 3}>
            <H4 className="bold">Contacts</H4>
          </Box>
        </Header>
      </Animation>
      {representatives.length === 0 ? (
        <Animation
          initial="hidden"
          animate="visible"
          variants={motionVariants}
          transition={{ ease: 'easeInOut', duration: 0.6, delay: 0 }}
        >
          <ZeroState>
            <Animation
              initial="hidden"
              animate="visible"
              variants={motionVariants}
              transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.4 }}
            >
              <H6>
                Our technology was unable to generate known representatives at this time.
                <br />
                We encourage you to research the appropriate people to share the message with.
              </H6>
            </Animation>
          </ZeroState>
        </Animation>
      ) : (
        <PrepresentativeListContainer>
          {/* TODO: Map over this list with real data */}
          {representatives &&
            representatives.map((rep) => (
              <Representative representative={rep} category={props.segment} />
            ))}
        </PrepresentativeListContainer>
      )}
    </RepresentativesGroup>
  )
}
