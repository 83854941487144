import React from 'react'
import ReactPlayer from 'react-player'
import styled from '@emotion/styled'

const StyledReactPlayer = styled(ReactPlayer)`
  iframe {
    border: 0;
    height: 100%;
  }
`

const HomepageVideo = (props) => {
  return (
    <StyledReactPlayer
      className="react-player"
      url={props.url}
      type={props.type}
      controls={false}
      width="100%"
      height="500px"
      playing
    />
  )
}
export default HomepageVideo
