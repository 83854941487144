import styled from '@emotion/styled'
import { Box, Flex } from '@rebass/emotion'
import { Textarea } from '@rebass/forms'
import { darken } from 'polished'
import React, { useState } from 'react'

import { Mq } from '../styles/settings'
import Animation from '../atoms/Animation'
import { ClipboardCopy } from '../atoms/Button'
import { H4, H6 } from '../atoms/Text'

const MessageContainer = styled.div`
  background-size: cover;
  background: ${(props) => props.theme.colors.brandSecondary};
  width: 100%;
  padding: 1rem;
  margin: 0 0 2rem;
  height: 540px;
  z-index: 2;
  cursor: pointer;
  position: relative;
  cursor: text;

  ${Mq.md} {
    width: 40%;
    margin: auto 0 auto auto;
  }
`

const Header = styled(Flex)`
  width: 100%;
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.darkGrey};
  flex-direction: column;

  H4 {
    font-weight: ${(props) => props.theme.weight.regular};
    color: ${(props) => props.theme.colors.white};
    padding-bottom: 1rem;
  }

  ${Mq.md} {
    flex-direction: row;
    H4 {
      margin-left: 1rem;
      padding-bottom: 0;
    }
  }
`

const Scroll = styled(H6)`
  position: absolute;
  bottom: 4rem;
  right: 0px;
  transform: rotate(-90deg);

  H6 {
    color: ${(props) => props.theme.colors.brandPrimary};
    font-weight: ${(props) => props.theme.weight.bold};
    animation: bounce 0.8s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }
  }

  @keyframes bounce {
    0% { transform: translateX(0); }
    100% { transform: translateX(16px); }
  }
`

const TextAreaContainer = styled(Box)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.white};
  height: calc(100% - 110px);
  overflow-x: hidden;
  overflow: auto;

  textarea#message {
    height: 100%;
    border: 0px;
    padding: 2rem 3rem 0 2rem;
    resize: none;

    p {
      max-width: 90%;
    }

    ::-webkit-scrollbar {
      width: 10px;
      background: ${(props) => props.theme.colors.lightGrey};
    }
    ::-webkit-scrollbar-track {
      background: ${(props) => props.theme.colors.lightGrey};
    }
    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.brandPrimary};
      transition: ${(props) => props.theme.transition};

      &:hover {
        background: ${(props) => darken(0.1, props.theme.colors.brandPrimary)};
      }
    }
  }

  ${Mq.md} {
    height: calc(100% - 90px);
  }
  ${Mq.xxl} {
    height: calc(100% - 80px);
  }
`

const defaultMessage = `Dear (Title + Representative Name),

My name is (Your Name) and I live in your district. After seeing the vulnerability of our food system caused by COVID and extreme weather, I am writing to encourage you to learn about soil health and how regenerative agriculture can be a solution for building new resilience, prosperity and health in our county.

Increased soil carbon helps increase crop resilience to extreme weather, reduce the need for costly & unhealthy chemical inputs, and makes the food grown more nutritious. Healthy soil is a major solution to climate change as well, which we desperately need more action on.

The film/s that have inspired me to write this message are (pick the films that have inspired you):

- Kiss The Ground movie, which shows how we can address the climate crisis by prioritizing soil health. Available to stream on Netflix. Learn more at kissthegroundmovie.com

- Farmers Footprint, a short film that focuses on an American four generation farming family and their learnings from transitioning away from conventional farming towards regenerative agriculture. Watch the short film for free at farmersfootprint.us/watch

- Gather Film is an intimate portrait of the growing movement amongst Native Americans to reclaim their spiritual, political and cultural identities through food sovereignty, while battling the trauma of centuries of genocide. Learn more at gather.film

I look forward to your response and continuing the conversation about how we can prioritize regenerative agriculture & soil healthy in our state/country/city.

Thank you for your consideration,

Your Name
`

export function Message() {
  const motionVariants = {
    hidden: {
      opacity: 0,
    },
    hiddenLeft: {
      opacity: 0,
      x: '-20px',
    },
    hiddenRight: {
      opacity: 0,
      x: '20px',
    },
    visible: {
      opacity: 1,
      x: '0px',
    },
  }
  const [message, setMessage] = useState(defaultMessage)

  const handleMessageChange = (e) => {
    setMessage(e.target.value)
  }

  return (
    <MessageContainer>
      <Header>
        <Animation
          initial="hiddenLeft"
          animate="visible"
          variants={motionVariants}
          transition={{ ease: 'easeInOut', duration: 0.6, delay: 1 }}
        >
          <H4>Your Message</H4>
        </Animation>
        <Animation
          initial="hiddenRight"
          animate="visible"
          variants={motionVariants}
          transition={{ ease: 'easeInOut', duration: 0.6, delay: 1 }}
        >
          <ClipboardCopy title="Copy to clipboard" value={message} />
        </Animation>
      </Header>

      <TextAreaContainer>
        <Textarea id="message" name="message" value={message} onChange={handleMessageChange} />
      </TextAreaContainer>
      <Scroll>
        <H6>Scroll</H6>
      </Scroll>
    </MessageContainer>
  )
}

export default Message
