import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { lighten } from 'polished'
import { Box, Flex, Link as ExternalLink } from '@rebass/emotion'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import { Mq } from '../styles/settings'
import FarmersFootprint from '../assets/ff.png'
import KissTheGround from '../assets/ktg.png'
import NerdsForEarth from '../assets/n4e.png'
import Animation from '../atoms/Animation'
import { Container } from '../atoms/Container'
import { H3, H6 } from '../atoms/Text'
import { ShareBox } from '../molecules/ShareBox'

const FooterContainer = styled.main`
  background: ${(props) => props.theme.colors.darkGrey};
  padding: 4% 0 0 0;
  text-align: center;

  H3 {
    max-width: 100%;
    text-align: center;
  }
  H6 {
    color: ${(props) => props.theme.colors.brandPrimary};
    text-align: center;
  }

  ${Mq.md} {
    padding: 6% 0;
    text-align: left;

    H6 {
      text-align: left;
    }

    H3 {
      max-width: 60%;
      text-align: left;
    }
  }

  @media screen and (min-width: 1600px) {
    H3 {
      max-width: 100%;
    }
  }
`

const CopyrightContainer = styled.main`
  background: ${(props) => props.theme.colors.black};
  padding: 1.5rem 0 1rem 0;

  H3,
  H6 {
    color: ${(props) => props.theme.colors.white};
    font-weight: ${(props) => props.theme.weight.bold};
    transition: ${(props) => props.theme.transition};

    &:hover {
      color: ${(props) => props.theme.colors.brandPrimary};
    }
  }
  H6.header {
    font-family: ${(props) => props.theme.fonts.header};
    text-transform: none;
    letter-spacing: 0px;
    font-weight: ${(props) => props.theme.weight.regular};
    transition: 0.2s ease-in-out;
    color: ${(props) => props.theme.colors.white};

    & a {
      font-weight: ${(props) => props.theme.weight.regular};

      &:hover,
      &:active,
      &:focus {
        color: ${(props) => props.theme.colors.white};
      }
    }
  }
  H6.link {
    color: ${(props) => props.theme.colors.brandPrimary};
    margin: 1rem 2rem 1rem 0;
    transition: ${(props) => props.theme.transition};

    &:hover {
      color: ${(props) => lighten(0.2, props.theme.colors.brandPrimary)};
    }
  }
  ${Mq.md} {
    padding: 1% 0;
  }
`

const Sponsors = styled(Box)`
  H3 {
    text-align: left;
  }

  H6 {
    margin: 3rem 0;
  }

  svg {
    margin-right: 1rem;
  }

  ${Mq.md} {
    margin-top: 0;
    text-align: right;
  }
`

const ThankYou = styled(Box)`
  padding: 2rem;
  svg {
    margin-right: 1rem;
  }
  ${Mq.md} {
    padding: 0;
  }
`

const Images = styled(Flex)`
  margin-right: -20px;
  img {
    max-height: 90px;
    margin-right: 25px;
    width: auto;
  }
  ${Mq.md} {
    margin-right: 0;

    img {
      margin-right: 55px;
    }
  }
`

const KTG = styled(Box)`
  img {
    max-height: 60px;
    padding: 0 2rem;
    margin-top: 1rem;
    ${Mq.md} {
      padding: 0;
      margin-top: 0;
      max-height: 90px;
    }
  }
`

export function Footer(props) {
  const motionVariants = {
    hiddenLeft: {
      opacity: 0,
      x: '-20px',
    },
    hiddenRight: {
      opacity: 0,
      x: '20px',
    },
    invisible: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      x: 0,
      y: 0,
    },
  }

  return (
    <>
      <FooterContainer>
        <Container>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            flexDirection={['column', 'column', 'row']}
          >
            <ThankYou>
              <Animation
                initial="hiddenLeft"
                animate="visible"
                variants={motionVariants}
                transition={{ ease: 'easeInOut', duration: 0.6 }}
              >
                <H3 whiteText>Healthy Soil = Healthy Planet = Healthy People</H3>
              </Animation>
              <Sponsors>
                <Animation
                  initial="hiddenLeft"
                  animate="visible"
                  variants={motionVariants}
                  transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }}
                >
                  <H6>This initiative is brought to you by:</H6>
                </Animation>
                <Animation
                  initial="hiddenLeft"
                  animate="visible"
                  variants={motionVariants}
                  transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.4 }}
                >
                  <Images>
                    <ExternalLink href="https://nerdsforearth.com/" target="_blank">
                      <img src={NerdsForEarth} alt="nerds for earth" />
                    </ExternalLink>
                    <ExternalLink href="https://kisstheground.com/" target="_blank">
                      <KTG>
                        <img src={KissTheGround} alt="kiss the ground" />
                      </KTG>
                    </ExternalLink>
                    <ExternalLink href="https://farmersfootprint.us/" target="_blank">
                      <img src={FarmersFootprint} alt="farmers footprint" />
                    </ExternalLink>
                  </Images>
                </Animation>
              </Sponsors>
            </ThankYou>

            <Animation
              initial="hiddenRight"
              animate="visible"
              variants={motionVariants}
              transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.6 }}
            >
              <ShareBox />
            </Animation>
          </Flex>
        </Container>
      </FooterContainer>
      <CopyrightContainer>
        <Container>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            flexDirection={['column', 'column', 'row']}
          >
            <Box>
              <Animation
                initial="hiddenLeft"
                animate="visible"
                variants={motionVariants}
                transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.6 }}
              >
                <H6 className="header">
                  Copyright 2020 | All rights reserved –{' '}
                  <a href="mailto:info@healthysoilspolicy.org">Contact Us</a>
                </H6>
              </Animation>
            </Box>
            <Box>
              <Animation
                initial="hiddenRight"
                animate="visible"
                variants={motionVariants}
                transition={{ ease: 'easeInOut', duration: 0.6, delay: 1 }}
              >
                <Flex justifyContent="space-between" alignItems="center">
                  {/* TODO: Add anchor link & scroll */}
                  {props.home && (
                    <AnchorLink href="#take-action" offset={40}>
                      <H6 className="link">Take Action</H6>
                    </AnchorLink>
                  )}
                  {props.screeningKits && (
                    <Link to="/">
                      <H6 className="link">Take Action</H6>
                    </Link>
                  )}

                  <Link to="/screening-kits">
                    <H6>Screening Kits</H6>
                  </Link>
                </Flex>
              </Animation>
            </Box>
          </Flex>
        </Container>
      </CopyrightContainer>
    </>
  )
}
