import React, { useContext, useState } from 'react'
import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom'
import Loader from 'react-loader-spinner'

import { Box, Flex } from '@rebass/emotion'
import { FormGroup } from '@material-ui/core'
import { CivicsContext } from '../contexts/GoogleCivicsContext'

import { Mq, Theme } from '../styles/settings'
import { FormField } from '../atoms/Input'
import { Button } from '../atoms/Button'
import { logLead } from '../atoms/googleSheets'

const FormWrapper = styled(FormGroup)`
  margin-top: 2rem;
  position: relative;
  button {
    margin-top: 2rem;
    float: right;
    min-width: 200px;
    outline: none;
  }

  ${Mq.md} {
    margin-top: 5rem;
    button {
      margin-top: 0;
    }
  }
`

const EmailFormField = styled(FormField)`
  width: 100%;
`

export function LeadCapture(props) {
  const history = useHistory()
  const { address, setAddress, setName, name, email, setEmail } = useContext(CivicsContext)
  const [loading, setLoading] = useState(false)

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }
  const handleNameChange = (e) => {
    setName(e.target.value)
  }
  const handleAddressChange = (e) => {
    setAddress(e.target.value)
  }

  const isEnabled = name && email && address
  return (
    <FormWrapper onSubmit={() => history.push('/take-action')}>
      <Flex width="auto" mx={-2} flexDirection={['column', 'column', 'row']}>
        <Box width={[1, 1, 1 / 3]} mt={[5, 5, 0]} px={2}>
          <FormField
            label="Full Name"
            placeholder="Please enter your full name"
            value={name}
            onChange={handleNameChange}
          />
        </Box>
        <Box width={[1, 1, 1 / 3]} mt={[5, 5, 0]} px={2}>
          <EmailFormField label="Email" email={true} value={email} onChange={handleEmailChange} />
        </Box>
        <Box width={[1, 1, 1 / 3]} mt={[5, 5, 0]} px={2}>
          <FormField
            label="Location"
            value={address}
            placeholder="Ex: 44 Soil St, City, State Zip"
            onChange={handleAddressChange}
            helper="Use home address for best results"
          />
        </Box>

        <Box width="auto" px={2}>
          {/* TODO: Make submit disabled until validated, send name / email to a list, and pass user location to TakeAction.jsx page */}
          {loading ? (
            <>
              <Button
                title={
                  <Loader
                    type="ThreeDots"
                    color={Theme.colors.black}
                    height={40}
                    width={50}
                  ></Loader>
                }
                type="submit"
              />
            </>
          ) : props.takeActionPage ? (
            <Button
              title="submit"
              type="submit"
              disabled={!isEnabled}
              onClick={() => window.location.reload()}
            />
          ) : (
            <Button
              title="submit"
              type="submit"
              disabled={!isEnabled}
              onClick={() => logLead(name, email, setLoading)}
            />
          )}
        </Box>
      </Flex>
    </FormWrapper>
  )
}

export default LeadCapture
