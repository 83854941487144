import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import styled from '@emotion/styled'
import { Box } from '@rebass/emotion'
import { NavLink } from 'react-router-dom'
import { Mq } from '../styles/settings/theme'
import { Toolbar, makeStyles, Drawer, IconButton } from '@material-ui/core'
import { XCircle, Menu } from 'react-feather'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import Animation from '../atoms/Animation'
import { Theme } from '../styles/settings/theme'
import { Container } from '../atoms/Container'
import { H5 } from '../atoms/Text'
import NerdsForEarth from '../assets/n4e.png'
import { SocialShares } from '../molecules/SocialShares'

// TODO: Clean and brand all of this

const MobileNavWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.colors.darkGrey};
  padding: 1rem 2rem 0.5rem;
  margin-top: 1.5rem;
`

const LeftNav = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: auto;

  img {
    max-width: 50px;
  }
`

const RightNav = styled(Box)`
  z-index: 1;

  line {
    color: #fff;
  }

  svg {
    width: 40px;
    height: 32px;
    margin-bottom: 12px;
    color: ${(props) => props.theme.colors.white};

    line {
      color: ${(props) => props.theme.colors.white};
    }
  }

  H5 {
    color: ${(props) => props.theme.colors.white};
    margin-bottom: 0px;
    text-transform: capitalize;
    margin-bottom: 2rem;
    transition: 0.2s ease-in-out;
    position: relative;

    &:hover {
      text-decoration: none;
      color: ${(props) => props.theme.colors.brandPrimary};
    }

    &:last-of-type {
      margin-right: 40px;
    }
  }

  a.navbar__link.navbar__link--active H5 {
    color: ${(props) => props.theme.colors.brandPrimary};
  }
`

const StyledDrawer = styled(Drawer)`
  flex-shrink: 0;
  padding-left: 1rem;
  background: ${(props) => props.theme.colors.darkGrey};

  H1 {
    font-size: 40px;
    margin: 1rem 0;
    min-width: 250px;
  }

  H5 {
    width: 100%;
    text-align: center;
    font-size: 24px;
    min-width: 250px;
    padding-bottom: 0.5rem;
    color: ${(props) => props.theme.colors.white};
  }
`

const DrawerHeader = styled(Box)`
  padding: 0;
  position: absolute;
  top: 0;
  z-index: 4;
  right: 0;
`

const StyledList = styled(Box)`
  padding: 0 1rem;
  width: 100%;
  z-index: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  svg {
    ${Mq.md} {
      display: none;
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0.75rem 0',
    border: '0px',
    top: '0',
    height: '3rem',

    '& .MuiDrawer-paper': {
      width: '100%',
      backgroundColor: Theme.colors.darkGrey,
    },

    '& .MuiIconButton-root': {
      padding: 0,
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: 0,
    },
    '& .MuiIconButton-label svg line': {
      color: Theme.colors.white,
    },
  },
  hide: {
    display: 'none',
  },
  closeIcon: {
    flex: 'auto',
    maxWidth: '56px',
    margin: '24px 28px auto auto',
    justifyContent: 'flex-end',
    width: 'max-content',
  },
  header: {
    width: '100%',
    border: '0px',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    height: '3rem',
  },
}))

export function MobileNavigation(props) {
  const motionVariants = {
    hidden: {
      opacity: 0,
    },
    hiddenRight: {
      opacity: 0,
      x: '1rem',
    },
    visible: {
      opacity: 1,
      x: 0,
    },
  }

  const classes = useStyles()

  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [false])

  return (
    <Toolbar className={classes.root}>
      <Container>
        <MobileNavWrapper>
          <LeftNav>
            <NavLink exact activeClassName="navbar__link--active" className="navbar__link" to="/">
              <img src={NerdsForEarth} alt="nerds for earth" />
            </NavLink>
          </LeftNav>

          <RightNav>
            <IconButton
              color={Theme.colors.white}
              aria-label="open drawer"
              onClick={() => setOpen(true)}
              edge="end"
              className={clsx(open && classes.hide)}
            >
              <Menu />
            </IconButton>

            <StyledDrawer variant="persistent" anchor="right" open={open}>
              <DrawerHeader className={clsx(open && classes.header)}>
                <IconButton onClick={() => setOpen(false)} className={clsx(classes.closeIcon)}>
                  <XCircle size={32} color={Theme.colors.white} />
                </IconButton>
              </DrawerHeader>
              <StyledList>
                {props.takeAction || props.screeningKits ? null : (
                  <Animation
                    initial="hiddenRight"
                    animate="visible"
                    variants={motionVariants}
                    transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.4 }}
                  >
                    <AnchorLink href="#take-action" onClick={() => setOpen(false)}>
                      <H5>Take Action</H5>
                    </AnchorLink>
                  </Animation>
                )}
                {props.screeningKits && (
                  <Animation
                    initial="hiddenRight"
                    animate="visible"
                    variants={motionVariants}
                    transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.4 }}
                  >
                    <Link to="/take-action">
                      <H5 className="link" onClick={() => setOpen(false)}>
                        Take Action
                      </H5>
                    </Link>
                  </Animation>
                )}
                <Animation
                  initial="hiddenRight"
                  animate="visible"
                  variants={motionVariants}
                  transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.6 }}
                >
                  <NavLink
                    exact
                    onClick={() => setOpen(false)}
                    activeClassName="navbar__link--active"
                    className="navbar__link"
                    to="/screening-kits"
                  >
                    <H5 light="true" className="cta">
                      Films
                    </H5>
                  </NavLink>
                </Animation>

                <SocialShares margin="auto" size={32} altSize="32px" iconSize={14} delay={0} />
              </StyledList>
            </StyledDrawer>
          </RightNav>
        </MobileNavWrapper>
      </Container>
    </Toolbar>
  )
}

export default MobileNavigation
