import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { Box, Flex } from '@rebass/emotion'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import NerdsForEarth from '../assets/n4e.png'
import Animation from '../atoms/Animation'
import { Container } from '../atoms/Container'
import { H6 } from '../atoms/Text'
import { SocialShares } from '../molecules/SocialShares'

const NavigationContainer = styled(Flex)`
  background: ${(props) => props.theme.colors.darkGrey};
  padding: 0.5rem 0;
  height: 4.25rem;
  justify-content: space-between;
  align-items: center;

  H6 {
    color: ${(props) => props.theme.colors.brandPrimary};
    margin: 1rem 0;
  }
`

const Logo = styled(Box)`
  display: flex;
  align-items: center;
  img {
    max-width: 50px;
  }
  H6.small {
    margin-left: 2rem;
    font-size: 0.75rem;
    font-weight: ${(props) => props.theme.weight.bold};
    color: ${(props) => props.theme.colors.white};
    margin-top: 4px;
  }
`

const Links = styled(Box)`
  display: flex;
  align-items: center;
  H6 {
    margin-left: 2rem;
    font-weight: ${(props) => props.theme.weight.bold};
    color: ${(props) => props.theme.colors.white};
    transition: ${(props) => props.theme.transition};
    font-size: 16px;

    &:hover {
      color: ${(props) => props.theme.colors.brandPrimary};
    }
  }
`

export function DesktopNavigation(props) {
  const motionVariants = {
    hidden: {
      opacity: 0,
    },
    hiddenRight: {
      opacity: 0,
      x: '1rem',
    },
    visible: {
      opacity: 1,
      x: 0,
    },
  }

  return (
    <NavigationContainer>
      <Container>
        <Flex justifyContent="space-between" alignItems="center">
          <Logo>
            <Link to="/">
              <Animation
                initial="hidden"
                animate="visible"
                variants={motionVariants}
                transition={{ ease: 'easeInOut', duration: 0.6, delay: 0 }}
              >
                <img src={NerdsForEarth} alt="nerds for earth" />
              </Animation>
            </Link>
            <Animation
              initial="hidden"
              animate="visible"
              variants={motionVariants}
              transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.1 }}
            >
              <H6 className="small">Soil policy action</H6>
            </Animation>
          </Logo>

          <Links>
            {props.takeAction || props.screeningKits ? null : (
              <Animation
                initial="hiddenRight"
                animate="visible"
                variants={motionVariants}
                transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.4 }}
              >
                <AnchorLink href="#take-action">
                  <H6>Take Action</H6>
                </AnchorLink>
              </Animation>
            )}
            {props.screeningKits && (
              <Animation
                initial="hiddenRight"
                animate="visible"
                variants={motionVariants}
                transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.4 }}
              >
                <Link to="/take-action">
                  <H6 className="link">Take Action</H6>
                </Link>
              </Animation>
            )}
            <Animation
              initial="hiddenRight"
              animate="visible"
              variants={motionVariants}
              transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }}
            >
              <Link to="/screening-kits">
                <H6>Films</H6>
              </Link>
            </Animation>
            <SocialShares margin="1rem" size={32} altSize="32px" iconSize={14} delay={0} />
          </Links>
        </Flex>
      </Container>
    </NavigationContainer>
  )
}
