import React from 'react'
import { useLocalStorage } from '../utils/hooks'

const defaultState = {
  name: '',
  address: '',
  email: '',
}

export const CivicsContextProvider = (props) => {
  const [name, setName] = useLocalStorage('soil-policy-name', '')
  const [address, setAddress] = useLocalStorage('soil-policy-address', '')
  const [email, setEmail] = useLocalStorage('soil-policy-email', '')

  return (
    <CivicsContext.Provider value={{ address, setAddress, name, setName, email, setEmail }}>
      {props.children}
    </CivicsContext.Provider>
  )
}
export const CivicsContext = React.createContext(defaultState)
