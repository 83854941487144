import React from 'react'
import { Flex, Box, Link } from '@rebass/emotion'
import styled from '@emotion/styled'
import { darken } from 'polished'
import { Globe, Phone } from 'react-feather'

import { Theme, Mq } from '../styles/settings/theme'
import Animation from '../atoms/Animation'

import {
  // EmailShareButton,
  EmailIcon,
  // FacebookShareButton,
  // FacebookIcon,
  // LinkedinShareButton,
  // LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share'

export function RepresentativeContacts(props) {
  const isMobile = window.innerWidth < 1000

  const motionVariants = {
    hiddenLeft: {
      opacity: 0,
      x: '-20px',
    },
    hiddenRight: {
      opacity: 0,
      x: '20px',
    },
    invisible: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      x: 0,
      y: 0,
    },
  }

  const StyledFlex = styled(Flex)`
    max-width: ${props.representative ? '100%' : '240px'};
    margin: 1rem 0 0;

    button.react-share__ShareButton {
      margin-left: 0;
      margin-right: 1rem;
      outline: none;
    }
    button.react-share__ShareButton svg circle {
      transition: ${(props) => props.theme.transition};
    }
    button.react-share__ShareButton:hover svg circle {
      fill: ${(props) => darken(0.15, props.theme.colors.brandPrimary)} !important;
    }
    a svg circle {
      transition: ${(props) => props.theme.transition};
    }
    a {
      &:hover svg circle {
        transition: ${(props) => props.theme.transition};
        fill: ${(props) => darken(0.15, props.theme.colors.brandPrimary)} !important;
      }
    }

    ${Mq.md} {
      margin-left: ${props.margin};
      margin: auto;

      button.react-share__ShareButton {
        margin-left: ${props.margin};
        margin-right: 0;
      }
    }
  `

  const IconButton = styled(Box)`
    background: ${(props) => props.theme.colors.brandPrimary};
    border-radius: ${props.altSize || '40px'};
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    transition: ${(props) => props.theme.transition};

    &:hover {
      background: ${(props) => darken(0.15, props.theme.colors.brandPrimary)};
    }

    ${Mq.md} {
      min-width: 40px;
      height: 40px;
      margin-right: 0;
      margin-left: 1rem;
    }
  `
  const getCategoryName = () => {
    return props.category === 'Local' || props.category === 'Federal' ? props.category : 'State'
  }

  const logAction = (channel) => {
    window.gtag('event', 'share', {
      event_category: channel,
      event_label: getCategoryName(),
    })
    return true
  }

  return (
    <Animation
      initial={isMobile ? 'hiddenLeft' : 'hiddenRight'}
      animate="visible"
      variants={motionVariants}
      transition={{ ease: 'easeInOut', duration: 0.6, delay: props.delay }}
    >
      <StyledFlex
        justifyContent={[
          'flex-start',
          'flex-start',
          props.representative ? 'flex-end' : 'space-between',
        ]}
        mx="auto"
      >
        {/* <FacebookShareButton url="https://soilpolicyaction.us" quote="Testing this quote">
          <FacebookIcon
            size={props.size || 40}
            round={true}
            bgStyle={
              props.color ? { fill: Theme.colors.brandPrimary } : { fill: Theme.colors.white }
            }
            iconFillColor={props.color ? Theme.colors.black : Theme.colors.black}
          />
        </FacebookShareButton> */}
        {props.twitter && (
          <TwitterShareButton
            title={
              '@' +
              props.twitter +
              ' I encourage you to learn more about the importance of soil health & how we can achieve personal & planetary health by implementing regenerative agriculture. To learn more & see which states are taking action visit'
            }
            url="https://soilpolicyaction.us"
            hashtags={['HealthySoilNow']}
            beforeOnClick={() => logAction('Twitter')}
          >
            <TwitterIcon
              size={props.size || 40}
              round={true}
              bgStyle={
                props.color ? { fill: Theme.colors.brandPrimary } : { fill: Theme.colors.white }
              }
              iconFillColor={props.color ? Theme.colors.black : Theme.colors.black}
            />
          </TwitterShareButton>
        )}
        {/* <LinkedinShareButton
          title="Title of the post"
          summary="Summary of the post"
          source="https://soilpolicyaction.us"
          url="https://soilpolicyaction.us"
        >
          <LinkedinIcon
            size={props.size || 40}
            round={true}
            bgStyle={
              props.color ? { fill: Theme.colors.brandPrimary } : { fill: Theme.colors.white }
            }
            iconFillColor={props.color ? Theme.colors.black : Theme.colors.black}
          />
        </LinkedinShareButton> */}
        {props.email && (
          <Link href={'mailto:' + props.email} target="_blank" onClick={() => logAction('email')}>
            <IconButton minWidth={props.altSize} height={props.size}>
              <EmailIcon
                size={props.size || 40}
                round={true}
                bgStyle={
                  props.color ? { fill: Theme.colors.brandPrimary } : { fill: Theme.colors.white }
                }
                iconFillColor={props.color ? Theme.colors.black : Theme.colors.black}
              />
            </IconButton>
          </Link>
        )}

        {props.representative && (
          <>
            {props.phoneNumber && (
              <Link href={'tel:1+' + props.phoneNumber} onClick={() => logAction('phoneNumber')}>
                <IconButton minWidth={props.altSize} height={props.size}>
                  <Phone color={Theme.colors.black} size={props.iconSize || 24} />
                </IconButton>
              </Link>
            )}
            {props.url && (
              <Link
                href={props.url}
                target="_blank"
                rel="noreferrer"
                onClick={() => logAction('website')}
              >
                <IconButton minWidth={props.altSize} height={props.size}>
                  <Globe color={Theme.colors.black} size={props.iconSize || 24} />
                </IconButton>
              </Link>
            )}
          </>
        )}
      </StyledFlex>
    </Animation>
  )
}
