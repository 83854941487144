import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Box } from '@rebass/emotion'
import { darken } from 'polished'
import { CornerRightUp, FileText, Video } from 'react-feather'

import { Theme, Mq } from '../styles/settings'
import { H5 } from '../atoms/Text'
import Animation from '../atoms/Animation'

const StyledSection = styled(Box)`
  width: 100%;
  padding: 1.5rem 3rem;
  background-color: ${(props) => props.theme.colors.lightGrey};
  display: block;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  cursor: pointer;
  transition: ${(props) => props.theme.transition};
  svg {
    transition: ${(props) => props.theme.transition};
  }
  H5.serif {
    font-family: ${(props) => props.theme.fonts.header};
    text-transform: none;
    letter-spacing: 1px;
  }
  H5 {
    margin-top: 1.25rem;
    padding-right: 1rem;
    transition: ${(props) => props.theme.transition};
    &.link {
      font-family: ${(props) => props.theme.fonts.header};
      font-weight: ${(props) => props.theme.weight.bold};
      letter-spacing: 0px;
      text-transform: none;
    }
  }
  p {
    margin-bottom: 1.5rem;
  }

  &.hovered {
    background-color: ${(props) => darken(0.015, props.theme.colors.lightGrey)};
  }

  ${Mq.md} {
    display: flex;
    H5 {
      padding-right: 0;
      margin-top: 0rem;
      &.link {
        padding-left: 2rem;
      }
    }
  }
`

const StyledLink = styled.main`
  display: flex;
  align-items: center;
  padding-bottom: 1rem;

  svg {
    margin-left: 0rem;
    margin-right: 1rem;
    margin-top: 1rem;
    ${Mq.md} {
      margin-top: -4px;
      margin-left: 1rem;
      margin-right: 0rem;
    }
  }
  ${Mq.md} {
    padding-bottom: 0;
  }
`

export function Resource(props) {
  const [state, setState] = useState({
    hover: false,
    redirect: false,
  })

  const motionVariants = {
    hidden: {
      opacity: 0,
      y: '-20px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  const showHover = () => {
    setState({ hover: true })
  }

  const removeHover = () => {
    setState({ hover: false })
  }

  const handleClick = () => {
    window.open(props.url, '_blank')
  }

  return (
    <Animation
      initial="hidden"
      animate="visible"
      variants={motionVariants}
      transition={{ ease: 'easeInOut', duration: 0.6, delay: 0 }}
      style={{ width: '100%' }}
    >
      <StyledSection
        onMouseEnter={showHover}
        onMouseLeave={removeHover}
        onClick={handleClick}
        className={state.hover && 'hovered'}
      >
        <H5 className="serif">{props.title}</H5>
        <StyledLink>
          {props.type === 'video' ? (
            <Video size={22} color={Theme.colors.brandPrimary} />
          ) : (
            <FileText size={22} color={Theme.colors.brandPrimary} />
          )}
          <H5
            style={
              state.hover
                ? {
                    color: Theme.colors.brandPrimaryDarken,
                  }
                : null
            }
            className="link"
            ariaLabel="resource link"
            tabIndex={0}
          >
            {props.link}
          </H5>
          <CornerRightUp
            size={22}
            color={state.hover ? Theme.colors.brandPrimary : Theme.colors.black}
          />
        </StyledLink>
      </StyledSection>
    </Animation>
  )
}
