import React from 'react'
import styled from '@emotion/styled'
import { withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import { Box } from '@rebass/emotion'

import { Theme, Mq } from '../styles/settings'
import { Paragraph, H6 } from './Text'

const BasicTextField = withStyles({
  root: {
    background: Theme.colors.white,
    height: '3rem',
    padding: '0 1.5rem',
    fontFamily: Theme.fonts.brandFont,
    width: '100%',
    fontSize: '1rem',
    transition: Theme.transition,
    borderBottom: '6px solid',
    borderBottomColor: Theme.colors.brandPrimary,

    '&:hover': {
      background: Theme.colors.lightGrey,
      borderBottomColor: '#4BCA9A',
    },
  },
})(InputBase)

const LargeTextField = withStyles({
  root: {
    background: Theme.colors.white,
    height: '20rem',
    padding: '0 2rem',
    fontFamily: Theme.fonts.brandFont,
    width: '100%',
    fontSize: '1rem',
    transition: Theme.transition,
    borderBottom: '6px solid',
    borderBottomColor: Theme.colors.brandPrimary,

    '&:hover': {
      background: Theme.colors.lightGrey,
      borderBottomColor: '#4BCA9A',
    },
  },
})(InputBase)

const CustomTextField = styled(Box)`
  position: relative;
  p {
    font-size: 12px;
    color: ${(props) => props.theme.colors.white};
    text-align: right;
    position: absolute;
    right: 0;
    top: -60px;
    ${Mq.md} {
      top: auto;
      left: 0;
      right: auto;
      bottom: -2rem;
    }
  }
  p.helper {
    display: none;

    ${Mq.md} {
      display: block;
    }
  }
`

const Title = styled(H6)`
  text-transform: uppercase;
  position: absolute;
  top: -2rem;
  color: ${(props) => props.theme.colors.white};
  font-weight: ${(props) => props.theme.weight.semiBold};
  letter-spacing: ${(props) => props.theme.letterSpacing.semiBold};
  ${Mq.md} {
    top: -2.5rem;
  }
`

export function FormField(props) {
  return (
    <>
      <CustomTextField>
        <Title>{props.label}</Title>
        <BasicTextField
          id="custom-css-standard-TextField"
          placeholder={props.placeholder}
          label="Custom CSS"
          value={props.value}
          onChange={props.onChange}
        />
        {props.helper && <Paragraph className="helper">{props.helper}</Paragraph>}
      </CustomTextField>
    </>
  )
}

export function MessageField(props) {
  return (
    <CustomTextField>
      <Title>{props.label}</Title>
      <LargeTextField
        id="custom-css-standard-TextField"
        placeholder={props.placeholder}
        label="Custom CSS"
        value={props.value}
        onChange={props.onChange}
      />
    </CustomTextField>
  )
}
