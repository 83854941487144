import React from 'react'
import styled from '@emotion/styled'
import { Flex, Box } from '@rebass/emotion'
import { RepresentativeContacts } from '../molecules/RepresentativeContacts'

import { Mq } from '../styles/settings/theme'
import Animation from '../atoms/Animation'
import { H5 } from '../atoms/Text'

const RepresentativesBox = styled(Box)`
  position: relative;
  background: ${(props) => props.theme.colors.white};
  width: 100%;
`

const RepresentativeContent = styled(Flex)`
  background: ${(props) => props.theme.colors.lightGrey};
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  flex-direction: column;

  H5.header {
    font-family: ${(props) => props.theme.fonts.header};
    text-transform: none;
    letter-spacing: 0px;
  }

  ${Mq.md} {
    flex-direction: row;
  }
`

const StyledBox = styled(Box)`
  H3 {
    min-width: max-content;
  }
  ${Mq.md} {
    H3 {
    }
  }
`

const getTwitterFromRepresentative = (officials) => {
  const twitterHandles =
    officials && officials.channels
      ? officials.channels.filter((channel) => channel.type === 'Twitter')
      : []

  return twitterHandles.length > 0 ? twitterHandles[0].id : ''
}
const getFacebookFromRepresentative = (officials) => {
  const twitterHandles =
    officials && officials.channels
      ? officials.channels.filter((channel) => channel.type === 'Facebook')
      : []

  return twitterHandles.length > 0 ? twitterHandles[0].id : ''
}
const getPhonenumberFromRepresentative = (official) => {
  return official && official.phones ? official.phones[0] : null
}
const getUrlFromRepresentative = (official) => {
  return official && official.urls ? official.urls[0] : null
}
const getEmailFromRepresentative = (official) => {
  return official && official.emails ? official.emails[0] : null
}
export function Representative(props) {
  const { representative } = props
  const isMobile = window.innerWidth < 1000

  const motionVariants = {
    hiddenLeft: {
      opacity: 0,
      x: '-1.5rem',
    },
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      x: 0,
    },
  }
  return (
    <RepresentativesBox>
      <RepresentativeContent mt={3}>
        <StyledBox width={[1, 1, 1 / 3]}>
          <Animation
            initial={isMobile ? 'hidden' : 'hiddenLeft'}
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.6, delay: 0 }}
          >
            <H5 className="header">{representative.name}</H5>
          </Animation>
        </StyledBox>
        <StyledBox width={[1, 1, 1 / 3]}>
          <Animation
            initial={isMobile ? 'hidden' : 'hiddenLeft'}
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }}
          >
            <H5 className="header">{representative.office.name}</H5>
          </Animation>
        </StyledBox>
        <Box width={[1, 1, 1 / 3]}>
          {/* TODO: Use these for the database, or copy it into an alt version - frontend is styled */}
          <RepresentativeContacts
            twitter={getTwitterFromRepresentative(representative)}
            facebook={getFacebookFromRepresentative(representative)}
            phoneNumber={getPhonenumberFromRepresentative(representative)}
            url={getUrlFromRepresentative(representative)}
            email={getEmailFromRepresentative(representative)}
            margin="1rem"
            color="primary"
            size={40}
            altSize="32px"
            iconSize={20}
            delay={0.4}
            representative
            category={props.category}
          />
        </Box>
      </RepresentativeContent>
    </RepresentativesBox>
  )
}
