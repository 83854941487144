import React, { useContext, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Box } from '@rebass/emotion'
import { AlertCircle } from 'react-feather'

import { Theme, Mq } from '../styles/settings/theme'
import { Animation } from '../atoms/Animation'
import { Container } from '../atoms/Container'
import { RepresentativeList } from '../molecules/RepresentativeList'
import { CivicsContext } from '../contexts/GoogleCivicsContext'

const RepresentativesWrapper = styled.div`
  position: relative;
  width: 100%;
  background: $ ${(props) => props.theme.colors.darkGrey};
`

const RepresentativesContainer = styled.div`
  background: ${(props) => props.theme.colors.darkGrey};
  padding: 4% 0 5%;
  position: relative;
`

const Alert = styled(Box)`
  color: ${(props) => props.theme.colors.white};
  padding-top: 7rem;
  padding-left: 2rem;
  margin-bottom: -6rem;
  align-items: center;
  display: flex;
  max-width: 90%;

  svg {
    margin-right: 1rem;
    width: 70px;
  }

  ${Mq.md} {
    max-width: 600px;
    width: 100%;
    padding-left: 0;
    padding-bottom: 4rem;
    padding-top: 0;

    p {
      padding-top: 2px;
    }

    svg {
      width: 50px;
    }
  }
`

//Tech debt this should be in the context

export function Representatives() {
  const { address } = useContext(CivicsContext)
  const [geoState, setGeoState] = useState()
  const [officials, setOfficials] = useState([])

  const parseData = (response) => {
    if (response.result) {
      const result = response.result
      const { offices, officials } = result
      const includedOffices = ['Mayor', 'Governor', 'Senator', 'Assemblymember', 'Representative']
      const relevantOffices =
        offices &&
        offices.filter((office) =>
          includedOffices.some((excludedOffice) => office.name.includes(excludedOffice)),
        )
      const officialsWithOffice = []
      relevantOffices &&
        relevantOffices.forEach((office) => {
          const officialIndices = office.officialIndices
          officialIndices.forEach((officalIdx) => {
            const offical = { ...officials[officalIdx], office: office }
            offical && officialsWithOffice.push(offical)
          })
        })
      const ocid = Object.keys(result.divisions).filter((key) => key.split('/').length === 3)[0]
      setGeoState(result.divisions[ocid].name)
      return officialsWithOffice
    } else return []
  }

  //This should live in the context
  const params = {
    address: address,
    levels: [
      'administrativeArea1',
      'administrativeArea2',
      'country',
      'international',
      'locality',
      'regional',
      'special',
      'subLocality1',
      'subLocality2',
    ],
  }
  useEffect(() => {
    let gapi = window.gapi
    // this is taken directly from Google documentation:
    // https://developers.google.com/api-client-library/javascript/start/start-js
    function start() {
      // 2. Initialize the JavaScript client library.
      gapi.client
        .init({
          apiKey: process.env.REACT_APP_GOOGLE_API,
          // clientId and scope are optional if auth is not required.
        })
        .then(function () {
          // 3. Initialize and make the API request.
          return gapi.client.request({
            path: '/civicinfo/v2/representatives',
            params: params,
          })
        })
        .then(
          function (response) {
            setOfficials(parseData(response))
          },
          function (reason) {
            console.log('Error: ' + reason.result.error.message)
          },
        )
    }
    // 1. Load the JavaScript client library.
    gapi.load('client', start)
  }, [])

  const motionVariants = {
    hiddenLeft: {
      opacity: 0,
      x: '-2rem',
    },
    visible: {
      opacity: 1,
      x: 0,
    },
  }

  return (
    <RepresentativesWrapper>
      <RepresentativesContainer>
        <Container>
          <Animation
            initial="hiddenLeft"
            animate="visible"
            variants={motionVariants}
            transition={{ ease: 'easeInOut', duration: 0.6, delay: 2.5 }}
          >
            <Alert>
              <AlertCircle size={50} color={Theme.colors.brandPrimary} /> If you only have a few
              minutes, focus on your federal representatives in the House and Senate.
            </Alert>
          </Animation>
          <RepresentativeList
            paddingTop={'6rem'}
            segment="Local"
            representatives={officials.filter(
              (o) =>
                o.office.levels.filter((l) => l !== 'country' && l !== 'administrativeArea1')
                  .length > 0,
            )}
          />
          <RepresentativeList
            representatives={officials.filter(
              (o) => o.office.levels.filter((l) => l === 'administrativeArea1').length > 0,
            )}
            segment={geoState}
          />
          <RepresentativeList
            representatives={officials.filter(
              (o) => o.office.levels.filter((l) => l === 'country').length > 0,
            )}
            segment="Federal"
          />
        </Container>
      </RepresentativesContainer>
    </RepresentativesWrapper>
  )
}
