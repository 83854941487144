import React from 'react'

import metaImage from '../assets/meta-image.jpg'
import { SmallHero } from '../organisms/Hero'
import MetaTags from 'react-meta-tags'

const NoMatch = () => {
  return (
    <main className="atomic-page">
      <MetaTags>
        <title>US Soil Policy Action – Growing Awareness for Regenerative Agriculture</title>
        <meta
          property="og:title"
          content="US Soil Policy Action – Grow Awareness for Regenerative Agriculture"
        />
        <meta
          name="description"
          content="An initiative to help you advocate for soil health by connecting with elected officials, grassroots organizers, educational films, and our interactive state policy map."
        />
        <meta property="og:image" content={metaImage} />
      </MetaTags>
      <SmallHero
        title="Oops!"
        noMatchHero="true"
        button="Back to home"
        subTitle="It looks like you landed on a page that does not exist. Please make sure you entered the correct URL. Better luck in your search next time!"
        marginBottom="6.75rem"
        paddingTop="6.75rem"
        marginTop="0px"
        margin="0"
        noSubContent
        homeButton
      />
    </main>
  )
}

export default NoMatch
