const { GoogleSpreadsheet } = require('google-spreadsheet')

export const logLead = async (name, email, setLoading) => {
  const spreadSheetId = process.env.REACT_APP_SPREADSHEET_ID
  const serviceEmail = process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL
  const sheetPrivateKey = process.env.REACT_APP_GOOGLE_PRIVATE_KEY
  const doc = new GoogleSpreadsheet(spreadSheetId)

  setLoading(true)
  //   doc.useApiKey(process.env.REACT_APP_GOOGLE_API)
  await doc.useServiceAccountAuth({
    client_email: serviceEmail,
    private_key: sheetPrivateKey,
  })
  //   await doc.useServiceAccountAuth(creds)

  await doc.loadInfo() // loads document properties and worksheets
  const sheet = doc.sheetsByIndex[0]
  await sheet.addRow({ name: name, email: email })
  setLoading(false)

  window.location = '/take-action'
}
