import React from 'react'
import MetaTags from 'react-meta-tags'
import metaImage from '../assets/meta-image.jpg'

import { WideContainer } from '../atoms/Container'
import { LargeHero } from '../organisms/Hero'
import { Signup } from '../organisms/Signup'
import { EducationSection } from '../organisms/EducationSection'

export function Homepage() {
  return (
    <main className="atomic-page">
      <MetaTags>
        <title>US Soil Policy Action – Growing Awareness for Regenerative Agriculture</title>
        <meta
          property="og:title"
          content="US Soil Policy Action – Grow Awareness for Regenerative Agriculture"
        />
        <meta
          name="description"
          content="An initiative to help you advocate for soil health by connecting with elected officials, grassroots organizers, educational films, and our interactive state policy map."
        />
        <meta property="og:image" content={metaImage} />
      </MetaTags>
      <WideContainer>
        <LargeHero />
        <Signup />
        <EducationSection />
      </WideContainer>
    </main>
  )
}

export default Homepage
